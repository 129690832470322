.firstLi{float:left;width:31%;height:330px;background-color:white;margin-top:30px;margin-bottom:30px;display:inline-block;}
.li{float:left;width:31%; height:330px;background-color:white;margin-left:35px;margin-top:30px;margin-bottom:30px;display:inline-block;}
.ul{display:inline-block;margin-bottom:40px}
.imageBox{width:100%;}
.txtBox{width:100%;height:130px; padding:8px; padding-left: 0px;word-break: break-all;  text-align:left;color:#626262}
.subTitle{margin-top:12px;margin-left:4px;font-weight:500;font-size:15px;  text-align:left}

@media screen and (max-width: 1079px){
    .ul li:nth-child(odd){margin-left: 14.5%}
    .ul li:nth-child(even){margin-left: 35px}
    .ul li{width:34%}

}

@media screen and (max-width: 768px){
    .ul li:nth-child(odd){margin:30px auto;}
    .ul li:nth-child(even){margin:30px auto;}
    .ul li{width: 100%;margin: 30px auto;}
    .subTitle{width:100%;max-width:350px;margin:0 auto;}
    .imageBox{text-align: center;}
    .txtBox{width:100%;max-width:350px;margin:0 auto;}

}